import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import {
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BASE_PATH as BASE_PATH_SSO } from '@paldesk/shared-lib/data-access/sso-generated';

import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { ArchiveTableComponent } from './calculation-archive/archive-table/archive-table.component';
// eslint-disable-next-line max-len
import { AppWrapperModule } from '@design-system/feature/app-wrapper-v2';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@shared-lib/data-access/identity-service-generated';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import { DeleteCalculationPopupComponent } from './calculation-archive/archive-table/delete-calculation-popup/delete-calculation-popup.component';
import { CalculationArchiveComponent } from './calculation-archive/calculation-archive.component';
import { DeleteConstructionPopupComponent } from './delete-construction-popup/delete-construction-popup.component';
import {
  BASE_PATH as BASE_PATH_CALCULATION_SERVICE,
  CalculationService,
  StorageService,
} from './shared/generated';
import { PacwebService } from './shared/services/pacweb.service';
import { SharedModule } from './shared/shared.module';
import { pacwebEffects, pacwebReducers } from './store';

@NgModule({
  imports: [
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.GA4_MEASUREMENT_ID,
    }),
    SharedLibFeatTranslationModule,
    SharedModule,

    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: (): DsAppWrapperConfig => ({
          environment: environment.environment,
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          appGatewayBasePath: environment.palfinger_app_gateway,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Pacweb',
          appName: 'Pacweb',

          appHelpPath: 'help',
          statusBannerProductsToCheck: ['PACWeb'],
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    DsBreadcrumbsModule,
    DsPageModule,
    BrowserModule,
    MatIconModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
      },
    }),

    StoreModule.forRoot(pacwebReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    pacwebEffects,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
  ],
  declarations: [
    AppComponent,
    CalculationArchiveComponent,
    ArchiveTableComponent,
    DeleteCalculationPopupComponent,
    DeleteConstructionPopupComponent,
  ],
  providers: [
    PacwebService,
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BASE_PATH_SSO,
      useFactory: () =>
        environment.palfinger_api_management + '/sales_software_options',
    },
    CalculationService,
    StorageService,
    {
      provide: BASE_PATH_CALCULATION_SERVICE,
      useFactory: () => environment.palfinger_app_gateway + '/pacweb',
    },
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
