// used to make sure all environments have same properties
export class EnvironmentBase {
  static environment = '';
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_app_gateway = '';
  static oidc_clientid = '';
  static oidc_scope = '';

  static domainsForApiManagementSubsKey: string[];
  static google_api_key = '';

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_EQUIPMENT_SCREEN: string;
    PALDESK_BASEPATH: string;
    STATUS_PALDESK_PAGE: string;
    APP_HELP_PATH: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_EQUIPMENT_SCREEN: '',
    PALDESK_BASEPATH: '',
    STATUS_PALDESK_PAGE: '',
    APP_HELP_PATH: '',
  };

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
  };

  static GA4_MEASUREMENT_ID = '';
  static application_insights_key = '';
}
